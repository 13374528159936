import { useEffect, useState } from 'react'
import { createContainer } from 'unstated-next'

const useAppState = () => {
  const [kids, setKids] = useState([])
  const [ownerships, setOwnerships] = useState([])
  const [managedZookeepers, setManagedZookeepers] = useState({})
  const [leftMenuDisplayed, setLeftMenuDisplayed] = useState(false)
  const [rightMenuDisplayed, setRightMenuDisplayed] = useState(false)

  useEffect(() => {
    const zookeepers = ownerships.reduce((acc, o) => {
      acc[o.zookeeper.username] = {
        ...o.zookeeper,
        ownershipId: o._id,
        ownershipPermissions: o.permissions,
      }
      return acc
    }, {})
    setManagedZookeepers(zookeepers)
  }, [ownerships])


  const addManagedZookeeper = (ownership) => {
    const newManagedZookeepers = { ...managedZookeepers }
    newManagedZookeepers[ownership.zookeeper.username] = {
      ...ownership.zookeeper,
      ownershipId: ownership._id,
      ownershipPermissions: ownership.permissions
    }
    setManagedZookeepers(newManagedZookeepers)
  }

  const removeManagedZookeeper = (username) => {
    const newManagedZookeepers = { ...managedZookeepers }
    delete newManagedZookeepers[username]
    setManagedZookeepers(newManagedZookeepers)
  }

  const removeManagedZookeepers = (usernames) => {
    const newManagedZookeepers = { ...managedZookeepers }
    usernames.map(u => {
      delete newManagedZookeepers[u]
      return null
    })
    setManagedZookeepers(newManagedZookeepers)
  }

  const updateManagedZookeeper = (username, payload) => {
    const newManagedZookeepers = { ...managedZookeepers }
    newManagedZookeepers[username] = {
      ...newManagedZookeepers[username],
      ...payload
    }
    setManagedZookeepers(newManagedZookeepers)
  }

  const removeKid = (zookeeper) => {
    setKids(kids => kids.filter(k => k._id !== zookeeper._id))
  }

  const addKid = (zookeeper) => {
    setKids(kids => ([...kids, zookeeper]))
  }

  const showLeftMenu = () => setLeftMenuDisplayed(true)
  const hideLeftMenu = () => setLeftMenuDisplayed(false)
  const toggleLeftMenu = () => setLeftMenuDisplayed((f) => !f)

  const showRightMenu = () => setRightMenuDisplayed(true)
  const hideRightMenu = () => setRightMenuDisplayed(false)
  const toggleRightMenu = () => setRightMenuDisplayed((f) => !f)


  const resetState = () => {
    setKids([])
    setOwnerships([])
    setManagedZookeepers({})
    setLeftMenuDisplayed(false)
    setRightMenuDisplayed(false)
  }

  return {
    kids,
    setKids,
    ownerships,
    setOwnerships,
    managedZookeepers,
    // Menu
    leftMenuDisplayed,
    rightMenuDisplayed,
    showLeftMenu,
    hideLeftMenu,
    toggleLeftMenu,
    showRightMenu,
    hideRightMenu,
    toggleRightMenu,
    addManagedZookeeper,
    removeManagedZookeeper,
    updateManagedZookeeper,
    removeManagedZookeepers,
    addKid,
    removeKid,
    resetState
  }
}

export const AppState = createContainer(useAppState)

export default AppState
