import { CachesRegistry } from '.'

export class CacheEntry<T = any> {

  ttl: number

  key: string

  value: T

  updated: number

  listeners: {
    change: ((value: T) => void)[]
    expiry: ((expired: boolean) => void)[]
  }

  timer: any

  expired: boolean

  constructor(key, value, ttl, updated?: number, expired?: boolean) {
    this.value =  value
    this.ttl = ttl
    this.key = key
    this.updated = updated || Date.now()
    this.listeners = { change: [], expiry: [] }
    this.expired = typeof expired === 'boolean' ? expired : this.isStale()
    this.registerTimer()
    CachesRegistry.attach(this)
  }

  isStale () {
    return Date.now() - this.updated > this.ttl
  }

  valueOf (): T {
    return this.value
  }

  public onChange (cb: (value: T) => void) {
    this.listeners.change.push(cb)
  }

  public onExpiryChange (cb: (expired: boolean) => void) {
    this.listeners.expiry.push(cb)
  }

  public update (value: T) {
    this.value = value
    this.updated = Date.now()
    this.listeners.change.forEach((listener) => listener(value))
    this.expired = false
    this.notifyExpiry()
    this.registerTimer()
    CachesRegistry.persist()
  }

  public markAsStale () {
    this.expire()
  }

  private registerTimer () {
    clearTimeout(this.timer)
    if (Date.now() - this.updated + (this.ttl * 1000) > 0) {
      this.timer = setTimeout(() => {
        this.expire()
      }, Date.now() - this.updated + (this.ttl * 1000))
    } else {
      this.expire()
    }
  }

  private expire () {
    this.expired = true
    this.notifyExpiry()
    CachesRegistry.persist()
  }

  private notifyExpiry = () => {
    this.listeners.expiry.forEach((listener) => listener(this.expired))
  }

  destroy () {
    clearTimeout(this.timer)
    CachesRegistry.destroy(this)
  }

  static init<T> (key, value, ttl, force?: boolean) {
    const cache = CacheEntry.get(key)
    if (cache) {
      if (cache.ttl !== ttl || force) {
        cache.destroy()
      } else {
        return cache
      }
    }
    return new CacheEntry<T>(key, value, ttl)
  }

  static get (key) {
    return CachesRegistry.get(key)
  }
}

export default CacheEntry
