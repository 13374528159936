// eslint-disable camelcase
import { useApolloClient } from '@apollo/client'
import gql from 'graphql-tag'
import Cookie from 'js-cookie'
import { CachesRegistry } from 'lib/cache'
import localforage from 'localforage'
import { useEffect, useState } from 'react'
import fetch from 'unfetch'
import { createContainer } from 'unstated-next'
import config from '../config'
import { cachePersistor } from '../data/apolloClient'
import useHubspotTracking from '../hooks/useHubspotTracking'
import { colors } from '../theme'
import { AppState } from './AppState'

const initialState = {
  isAuthenticated: null,
  currentUser: null,
}

const COOKIE_SETTINGS = {
  production: { domain: '.nightzookeeper.com', secure: true, expires: 7 },
  staging: { domain: '.nightzookeeper.com', secure: true, expires: 7 },
  development: { domain: 'localhost', secure: false, expires: 7 },
}

interface IToken {
  expiresAt: Date
  value: string
}

interface ICurrentUser {
  accountOwnerships: any[]
  tutorial?: {
    manageFeatures?: boolean
  }
  _id: string
  type: string
  countryCode: string
  name: string
  email: string
  userType: 'teacher' | 'parent' | undefined
  customPlan: boolean
  accountOrigin: string
  subscriptionEndDate: Date
  createdAt: Date
  children: {
    _id: string
    nickname: string
    username: string
    yearGroup: number
    age: number
    starTutoring?: {
      _id: string
      willCancelAt: string
      subscriptionExpiresAt: string
      status: string
    }
    birthMonth: number
    birthYear: number
    avatar: {
      url: string
    }
    countryCode: string
  }[]
  subscription: {
    id: string
    type: string
    status: string
    hasAccessToResources: boolean
    current_period_end?: number
    plan?: {
      id?: string
      currency?: string
    }
  }
}

const useCurrentUser = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(
    initialState.isAuthenticated
  )
  const client = useApolloClient()
  const [currentUser, setCurrentUser] = useState<ICurrentUser | null>(
    initialState.currentUser
  )
  const [primary, setPrimary] = useState(colors.primary)
  const { resetState } = AppState.useContainer()
  const { identify } = useHubspotTracking()
  const [signUp, setSignUp] = useState(false)

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      // @ts-ignore
      typeof window.profitwell === 'function' &&
      currentUser?._id
    ) {
      // @ts-ignore
      window.profitwell('start', { user_email: currentUser.email })
    }
  }, [currentUser])

  const storeToken = async ({
    user,
    accessToken,
    accessTokenExpiresAt,
    refreshToken,
    refreshTokenExpiresAt,
  }) => {
    // NZK.com adapter
    if (user && user.type === 'parent') {
      localStorage.setItem('lscache-v2-user-id', user._id)
      localStorage.setItem('lscache-v2-shared-secret-key', user.sharedSecretKey)
      localStorage.setItem('lscache-v2-user-type', user.type)

      await localforage.setItem('token', {
        value: accessToken,
        expiresAt: accessTokenExpiresAt,
      })
    }
    Cookie.set(
      'nzk-edu-token',
      JSON.stringify({
        accessToken: {
          value: accessToken,
          expiresAt: accessTokenExpiresAt,
        },
        refreshToken: {
          value: refreshToken,
          expiresAt: refreshTokenExpiresAt,
        },
      }),
      COOKIE_SETTINGS[config.env] || {}
    )
    return true
  }

  const tryCookieAuth = async () => {
    let cookie = Cookie.get('nzk-edu-token')
    if (!cookie) {
      const cookieB64 = Cookie.get('nzk_parent_token')
      if (cookieB64 && typeof window !== 'undefined' && Boolean(window.atob)) {
        const rawCookie = window.atob(cookieB64)
        try {
          const parsedCookie = JSON.parse(rawCookie)
          cookie = JSON.stringify({
            accessToken: {
              value: parsedCookie.accessToken,
              expiresAt: parsedCookie.accessTokenExpiresAt,
            },
            refreshToken: {
              value: parsedCookie.refreshToken,
              expiresAt: parsedCookie.refreshTokenExpiresAt,
            },
          })
        } catch (err) {
          //
        }
      }
    }
    if (cookie) {
      try {
        const token = JSON.parse(cookie)
        await storeToken({
          user: null,
          accessToken: token.accessToken.value,
          accessTokenExpiresAt: token.accessToken.expiresAt,
          refreshToken: token.refreshToken.value,
          refreshTokenExpiresAt: token.refreshToken.expiresAt,
        })
      } catch (err) {
        Cookie.remove('nzk-edu-token', COOKIE_SETTINGS[config.env] || {})
        Cookie.remove('nzk_parent_token', COOKIE_SETTINGS[config.env] || {})
      }
    }
  }

  const removeEduTokenCookie = () => {
    Cookie.remove('nzk-edu-token', COOKIE_SETTINGS[config.env] || {})
  }

  const logout = async () => {
    await localforage.removeItem('token')
    await cachePersistor.purge()
    client.clearStore()
    await client.cache.reset()
    await CachesRegistry.clear()
    // NZK.com adapter
    localStorage.removeItem('lscache-v2-user-id')
    localStorage.removeItem('lscache-v2-shared-secret-key')
    localStorage.removeItem('lscache-v2-user-type')
    removeEduTokenCookie()
    try {
      Cookie.remove('nzk_parent_token', COOKIE_SETTINGS[config.env] || {})
    } catch (err) {
      //
    }
    setCurrentUser(null)
    setIsAuthenticated(false)
    localforage.clear()
    resetState()
    if (typeof window === 'undefined') return
    window.Sentry.configureScope((scope) => {
      scope.setUser(null)
    })
  }

  const initCurrentUser = async () => {
    const { data } = await client.query({
      query: gql`
        query me {
          me {
            _id
            type
            countryCode
            name
            email
            userType
            createdAt
            hasPassword
            customPlan
            accountOrigin
            subscriptionEndDate
            tutorial {
              manageFeatures
            }
            children {
              _id
              username
              nickname
              avatar {
                url
              }
              starTutoring {
                _id
                willCancelAt
                subscriptionExpiresAt
                status
              }
              yearGroup
              age
              birthMonth
              birthYear
            }
            subscription {
              id
              type
              hasAccessToResources
              current_period_end
              status
            }
          }
        }
      `,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
    })
    if (!data || !data.me) return

    // logout if wrong user type
    if (data.me.type !== 'parent') {
      logout()
      return
    }

    // sentry & hubspot setup
    const name = data.me.name || ''
    let firstname = name || ''
    let lastname = ''
    if (name.split(' ').length > 1) {
      firstname = name.split(' ')[0]
      lastname = name.split(' ')[1]
    }

    if (window.Sentry) {
      window.Sentry.configureScope((scope) => {
        scope.setUser({
          id: data.me._id,
          email: data.me.email,
        })
      })
    }

    identify({
      email: data.me.email,
      firstname,
      lastname,
      country: data.me.countryCode,
    })
    // amplitude
    if (typeof amplitude !== 'undefined') {
      amplitude.getInstance().setUserId(data.me._id)
      const identity = new amplitude.Identify().set('type', data.me.type)
      amplitude.getInstance().identify(identity)
    }
    // set the user
    setCurrentUser({ ...data.me })
  }

  const onLoginSuccess = async (data?: any, signUp?: boolean) => {
    if (typeof window === 'undefined') return
    if (data) {
      await storeToken(data)
    }
    setSignUp(signUp || false)
    if (signUp) {
      localStorage.setItem('should-show-welcome-video', 'true')
    }
    setIsAuthenticated(true)
    await initCurrentUser()
    return true
  }

  const login = async (email, password, signUp = false) => {
    const token = await localforage.getItem('token')
    if (token) return onLoginSuccess()
    if (!email || !password) return
    const body = {
      client_id: config.oauthClientId,
      grant_type: 'password',
      username: email && email.toLowerCase(),
      password,
    }
    const URLSearchParams = Object.keys(body)
      .map((key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(body[key])}`
      })
      .join('&')
    const stream = await fetch(`${config.oauthEndpoint}/oauth/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: URLSearchParams,
    })
    const data = await stream.json()
    if (!data || data.statusCode >= 500)
      throw new Error('An unexpected has occured!')
    if (
      data &&
      data.user &&
      data.user.type !== 'parent' &&
      data.user.email !== 'ben@nightzookeeper.com'
    )
      throw new Error('Only parents can login here.')
    if (data && data.accessToken) return onLoginSuccess(data, signUp)
    throw new Error('Incorrect email or password.')
  }

  const init = async () => {
    await tryCookieAuth()
    const token: IToken | null = await localforage.getItem('token')
    if (token && token.value && new Date(token.expiresAt) > new Date()) {
      return onLoginSuccess()
    }
    await localforage.removeItem('token')
    setCurrentUser(null)
    setIsAuthenticated(false)
  }

  useEffect(() => {
    init()
  }, [])

  const updateCurrentUser = (newUser) => {
    setCurrentUser((u) => ({ ...u, ...newUser }))
  }

  return {
    isAuthenticated,
    initCurrentUser,
    currentUser,
    signUp,
    login,
    logout,
    updateCurrentUser,
    primary,
    setPrimary,
    storeToken,
    removeEduTokenCookie,
  }
}

export const CurrentUserState = createContainer(useCurrentUser)

export const CurrentUserStateProvider = CurrentUserState.Provider
export const useCurrentUserState = CurrentUserState.useContainer
export default CurrentUserState
