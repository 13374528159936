import localforage from 'localforage'
import { useEffect, useState } from 'react'
import { createContainer } from 'unstated-next'
import { colors } from '../theme'

const initialState = {
  primary: colors.primary,
  colors: {
    red: '#E61F24',
    green: '#8BC439',
    orange: '#FF8B00'
  }
}

const useTheme = () => {
  const [theme, setTheme] = useState(initialState)
  const primary = theme.primary
  const setPrimary = (primary) => setTheme({ ...theme, primary })

  const onInit = async () => {
    let theme = await localforage.getItem('theme')
    setTheme({ ...initialState, ...JSON.parse(theme) })
  }

  const onThemeChanged = async () => {
    return localforage.setItem('theme', JSON.stringify(theme))
  }

  useEffect(() => {
    onInit()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    onThemeChanged()
    // eslint-disable-next-line
  }, [theme])


  return { theme, setTheme, primary, setPrimary }
}

export const ThemeState = createContainer(useTheme)
