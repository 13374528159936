import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { ThemeState } from '../state/ThemeState'

const Theme = ({ children }) => {
  const { theme } = ThemeState.useContainer()

  return <ThemeProvider theme={{}}>
    { children }
  </ThemeProvider>
}

Theme.propTypes = {
  children: PropTypes.node
}

Theme.defaultProps = {

}

export default Theme
