export const colors = {
  white: '#FFF',
  lighterGray: '#FAFAFA',
  gray: '#DBDBDB',
  blue: '#46B2E4',
  primary: '#5849BE',
  green: '#41C152',
  red: '#FF4C41',
  purple1: '#1A0425',
  purple2: '#1C042B',
  purple3: '#341644',
  green1: '#579820',
  green2: '#8CC63F',
  green3: '#AFD752',
  gray1: '#AFAFAF',
  gray2: '#DDDBD7',
  red1: '#901A26',
  red2: '#DE1323',
  red3: '#FB263B',
  purple: '#341644',
}

export const theme = {
  global: {
    font: {
      family: 'Libre Baskerville',
      size: '14px',
      height: '20px'
    },
    colors: {
      primary: colors.indigo,
      brand: '#E3E8EE',
      brandText: '#1A1F36',
      text: {
        light: colors.darkPurple
      }
    },
    table: {
      header: {
        background: colors.lighterGray,
        border: 'bottom'
      },
      body: {
        border: 'bottom'
      }
    }
  },
  button: {
    border: {
      radius: '5px',
      color: colors.indigo
    },
    primary: {
      color: colors.indigo
    },
    extend: props => {
      let extraStyles = ''
      if (props.primary) {
        extraStyles = `
        text-transform: uppercase;
        color: white;
        padding: 6px 12px;
          `
      }
      return `
          font-size: 12px;
          font-weight: bold;
          ${extraStyles}
        `
    }
  },
  checkBox: {
    color: {
      "dark": colors.primary,
      "light": colors.primary
    },
    border: {
      color: {
        "dark": colors.primary,
        "light": colors.primary
      }
    },
    hover: {
      border: {
        color: {
          "dark": colors.primary,
          "light": colors.primary
        }
      }
    },
    toggle: {
      color: {
        "dark": colors.primary,
        "light": colors.primary
      }
    }
  },
  rangeInput: {
    track: {
      color: "#fff",
      height: "12px",
      extend: () => `border-radius: 10px`,
    },
    thumb: {
      color: colors.primary,
    },
  },
}

export default theme
