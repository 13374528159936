import React, { useState } from 'react'
import { createContainer } from 'unstated-next'

const ModalStateFunc = () => {
  const [modals, setModals] = useState([])

  const displayModal = (component) => {
    setModals([...modals, component])
  }

  const dismissModal = () => {
    setModals(modals.slice(modals.length - 1, 1))
  }

  return { modals, displayModal, dismissModal }
}

export const ModalState = createContainer(ModalStateFunc)
export const useModalState = ModalState.useContainer

const ModalManager = (props) => {
  const { modals } = ModalState.useContainer()
  return <>
    { modals.map((m, i) => React.cloneElement(m, { key: i })) }
    { props.children }
  </>
}

export const ModalProvider = ({ children }) => {
  return <ModalState.Provider>
    <ModalManager>
        { children }
    </ModalManager>
  </ModalState.Provider>
}

