
import localforage from 'localforage'
import { CacheEntry } from '.'

interface CacheStorage<T = string> {
  get: (key: string) => T | null | Promise<T | null>
  set: (key: string, value: T) => boolean | Promise<boolean>
  del: (key: string) => boolean | Promise<boolean>
}

export const LocalStorageCacheStorage: CacheStorage = {
  get: (key) => localStorage.getItem(key),
  set: (key, value) => {
    localStorage.setItem(key, value)
    return true
  },
  del: (key) => {
    localStorage.removeItem(key)
    return true
  }
}

export const SessionStorageCacheStorage: CacheStorage = {
  get: (key) => sessionStorage.getItem(key),
  set: (key, value) => {
    sessionStorage.setItem(key, value)
    return true
  },
  del: (key) => {
    sessionStorage.removeItem(key)
    return true
  }
}

const FakeCacheStorage: CacheStorage = {
  get: () => null,
  set: () => true,
  del: () => true
}

export const LocalForageCacheStorage: CacheStorage = {
  get: (key) => localforage.getItem(key),
  set: (key, value) => {
    localforage.setItem(key, value)
    return true
  },
  del: (key) => {
    localforage.removeItem(key)
    return true
  }
}

const getDefaultStorage = (): CacheStorage => {
  if (typeof(Storage) !== "undefined") return SessionStorageCacheStorage
  return FakeCacheStorage
} 

export class CachesRegistryClass {
  caches: Map<string, CacheEntry>

  STORAGE_KEY = 'cache-registry'

  VERSION = '1'

  storage = getDefaultStorage()

  constructor (caches?: Map<string, CacheEntry>) {
    this.caches = new Map<string, CacheEntry>(caches || [])
  }

  attach (cache) {
    this.caches.set(cache.key, cache)
    this.persist()
  }

  get (key) {
    return this.caches.get(key)
  }

  destroy (cache, persist?: boolean) {
    this.caches.delete(cache.key)
    if (persist === undefined || persist === true) {
      this.persist()
    }
  }

  async restore () {
    const v = await this.storage.get(this.STORAGE_KEY)
    if (!v) return
    try {
      const payload = JSON.parse(v)
      if (payload.version !== this.VERSION) {
        this.clear()
        return
      }
      payload.entities.forEach(e => {
        const cache = new CacheEntry(e.key, e.value, e.ttl, e.updated, e.expired)
        this.attach(cache)
      })
    } catch (err) {
      console.error(err)
    }
  }

  async persist () {
    const caches = Array.from(this.caches.entries()).map(([,value]) => {
      return {
        ttl: value.ttl,
        updated: value.updated,
        value: value.value,
        key: value.key,
        expired: value.expired
      }
    })
    const payload = {
      version: this.VERSION,
      entities: caches
    }
    await this.storage.set(this.STORAGE_KEY, JSON.stringify(payload))
  }

  async clear () {
    Array.from(this.caches.entries()).forEach(([, value]) => {
      value.destroy()
    })
    await this.storage.del(this.STORAGE_KEY)
    await this.persist()
  }
}

export const CachesRegistry = new CachesRegistryClass()

export default CachesRegistry

