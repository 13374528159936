const useHubspotTracking = () => {
  // @ts-ignore
  let _hsq = window._hsq = window._hsq || [];

  const identify = (payload) => {
    _hsq.push(['identify', {
      ...payload
    }])
  }

  const trackPage = (path) => {
    _hsq.push(['setPath', path]);
    _hsq.push(['trackPageView']);
  }

  return {
    _hsq,
    identify,
    trackPage
  }
}

export default useHubspotTracking
