import React from 'react'
import localforage from 'localforage'

class ErrorBoundary extends React.Component {
  componentDidCatch(error, errorInfo) {
    if (new RegExp('Not Authorised').test(error.message) ||
    new RegExp('Access Denied').test(error.message)
    ) {
      localforage.getItem('token').then(token => {
        window.Sentry.addBreadcrumb({
          message: 'Not authorised!',
          data: {
            token: JSON.stringify(token)
          }
        })
        return window.Sentry.captureException(error)
      })
      return
    }
    if (new RegExp('QuotaExceededError').test(error.message)) {
      window.Sentry.addBreadcrumb({
        category: 'storage',
        data: {
          driver: localforage.driver()
        }
      })
    }
    window.Sentry.captureException(error)
  }

  render() {
    return this.props.children
  }
}

export default ErrorBoundary
