import { ApolloProvider } from "@apollo/client"
import { Grommet } from "grommet"
import React from "react"
import { StripeProvider } from "react-stripe-elements"
import { ModalProvider as NZKModalProvider } from 'nzk-react-components'
import ErrorBoundary from "./src/components/ErrorBoundary"
import Theme from "./src/components/Theme"
import config from "./src/config"
import { client } from "./src/data/apolloClient"
import { AppState } from "./src/state/AppState"
import { CurrentUserStateProvider } from "./src/state/CurrentUserState"
import { ModalProvider } from "./src/state/ModalState"
import { ThemeState } from "./src/state/ThemeState"
import theme from "./src/theme"
import { CachesRegistry } from './src/lib/cache'
import './src/styles/index.css'

CachesRegistry.restore()

// eslint-disable-next-line
export const wrapRootElement = ({ element }) => (
  <>
    <ErrorBoundary>
        <ApolloProvider client={client}>
            <AppState.Provider>
              <CurrentUserStateProvider>
                <ThemeState.Provider>
                  <StripeProvider apiKey={config.stripeKey}>
                      <Grommet theme={theme}>
                        <Theme>
                          <NZKModalProvider>
                            <ModalProvider>
                              {element}
                            </ModalProvider>
                          </NZKModalProvider>
                        </Theme>
                      </Grommet>
                  </StripeProvider>
                </ThemeState.Provider>
              </CurrentUserStateProvider>
            </AppState.Provider>
        </ApolloProvider>
    </ErrorBoundary>
  </>
)
